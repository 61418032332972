<template>
  <section class="app-container base-proto-group-page">
    <slot name="top" :sup_this="sup_this"></slot>
    <EHeader :sup_this="sup_this" :query="query" :formField="formField" @searchChange="searchChange" />
    <slot name="batchOperation" :sup_this="sup_this">
      <AddOrEditProtoGroup v-if="permissionList.hasAddProtoGroup" @successHandler="init" />
      <div style="min-height: 30px"></div>
    </slot>

    <div class="table-wrapper">
      <CommonTable
        ref="table"
        height="100%"
        :selection="false"
        :tableLoading="tableLoading"
        :cols="cols"
        :infoData="data"
        @selection-change="handleSelectionChange"
      >
        <template #nameSlot="{ row }">
          <div>
            {{ row.name }}
          </div>
          <div class="default-group">
            {{ row.isDefault ? '默认分组' : '' }}
          </div>
        </template>

        <template #enableSlot="{ row }"> {{ enable(row) }}</template>

        <template #isDefaultSlot="{ row }"> {{ defaultGroup(row) }} </template>

        <el-table-column fixed="right" align="center" label="操作" width="300px">
          <template slot-scope="{ row }">
            <AssignDistributor
              v-if="showAssignDistributor(row)"
              @successHandler="init"
              class="line"
              :sup_this="sup_this"
              :isEdit="true"
              :data="row"
            />
            <AddOrEditProtoGroup
              v-if="showEditProtoGroup(row)"
              @successHandler="init"
              :sup_this="sup_this"
              :isEdit="true"
              :isExtend="row.enable == 1"
              :data="row"
              class="line"
            />

            <AbleProtoBtn v-if="showAbleProtoBtn(row)" :sup_this="sup_this" :data="row" class="line" />
            <slot name="menu" :row="row" :sup_this="sup_this"></slot>
          </template>
        </el-table-column>
      </CommonTable>
    </div>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </section>
</template>

<script>
import EHeader from './module/header'
import { AssignDistributor, AddOrEditProtoGroup, AbleProtoBtn } from './module'
import { initDataMixin } from '@/mixins'
import {
  PROTO_GROUP_ENABLE_OBJ,
  PROTO_GROUP_DEFAULT_OBJ,
  PROTO_GROUP_ENABLE,
  PROTO_GROUP_DISENABLE,
  PROTO_GROUP_IS_DEFAULT
} from '@/utils/constant'

export default {
  components: {
    EHeader,
    AddOrEditProtoGroup,
    AbleProtoBtn,
    AssignDistributor
  },

  mixins: [initDataMixin],

  props: {
    cols: {
      type: Array,
      required: true
    },

    formField: {
      type: Array,
      required: true
    },

    resetUrl: String,

    resetMergeData: {
      type: Object,
      default: () => ({
        orderItems: [
          {
            asc: false,
            column: 'create_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ]
      })
    },

    hasAddProtoGroup: Boolean,
    hasAssignDistributor: Boolean,
    hasEditProtoGroup: Boolean,
    hasEnableProtoBtn: Boolean,
    hasDisableProtoBtn: Boolean
  },
  data() {
    return {
      sup_this: this,
      url: this.resetUrl
    }
  },

  computed: {
    permissionList({
      hasAddProtoGroup,
      hasAssignDistributor,
      hasEditProtoGroup,
      hasEnableProtoBtn,
      hasDisableProtoBtn
    }) {
      return {
        hasAddProtoGroup,
        hasAssignDistributor,
        hasEditProtoGroup,
        hasEnableProtoBtn,
        hasDisableProtoBtn
      }
    },

    showAbleProtoBtn({ permissionList }) {
      return (row) => {
        const { isDefault, enable } = row
        if (
          (enable == PROTO_GROUP_ENABLE && !permissionList.hasDisableProtoBtn) ||
          (enable == PROTO_GROUP_DISENABLE && !permissionList.hasEnableProtoBtn)
        ) {
          return false
        }
        if (enable == PROTO_GROUP_ENABLE && PROTO_GROUP_IS_DEFAULT == isDefault) {
          return false
        }
        return true
      }
    },

    showEditProtoGroup({ permissionList }) {
      return (row) => {
        const { enable } = row
        return permissionList.hasEditProtoGroup
      }
    },

    showAssignDistributor({ permissionList }) {
      return (row) => {
        const { enable } = row
        if (!permissionList.hasAssignDistributor) return false
        if (enable == PROTO_GROUP_ENABLE) {
          return true
        }
        return false
      }
    },

    enable() {
      return (row) => {
        return PROTO_GROUP_ENABLE_OBJ[row.enable]
      }
    },

    defaultGroup() {
      return (row) => {
        return PROTO_GROUP_DEFAULT_OBJ[row.isDefault]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-proto-group-page {
  display: flex;
  flex-direction: column;
  .table-wrapper {
    flex: 1;
    overflow: hidden;
  }
}
.default-group {
  font-size: 12px;
  float: right;
  color: $color-info;
}
</style>
