var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"app-container base-proto-group-page"},[_vm._t("top",null,{"sup_this":_vm.sup_this}),_c('EHeader',{attrs:{"sup_this":_vm.sup_this,"query":_vm.query,"formField":_vm.formField},on:{"searchChange":_vm.searchChange}}),_vm._t("batchOperation",[(_vm.permissionList.hasAddProtoGroup)?_c('AddOrEditProtoGroup',{on:{"successHandler":_vm.init}}):_vm._e(),_c('div',{staticStyle:{"min-height":"30px"}})],{"sup_this":_vm.sup_this}),_c('div',{staticClass:"table-wrapper"},[_c('CommonTable',{ref:"table",attrs:{"height":"100%","selection":false,"tableLoading":_vm.tableLoading,"cols":_vm.cols,"infoData":_vm.data},on:{"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"nameSlot",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.name)+" ")]),_c('div',{staticClass:"default-group"},[_vm._v(" "+_vm._s(row.isDefault ? '默认分组' : '')+" ")])]}},{key:"enableSlot",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.enable(row)))]}},{key:"isDefaultSlot",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.defaultGroup(row))+" ")]}}])},[_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.showAssignDistributor(row))?_c('AssignDistributor',{staticClass:"line",attrs:{"sup_this":_vm.sup_this,"isEdit":true,"data":row},on:{"successHandler":_vm.init}}):_vm._e(),(_vm.showEditProtoGroup(row))?_c('AddOrEditProtoGroup',{staticClass:"line",attrs:{"sup_this":_vm.sup_this,"isEdit":true,"isExtend":row.enable == 1,"data":row},on:{"successHandler":_vm.init}}):_vm._e(),(_vm.showAbleProtoBtn(row))?_c('AbleProtoBtn',{staticClass:"line",attrs:{"sup_this":_vm.sup_this,"data":row}}):_vm._e(),_vm._t("menu",null,{"row":row,"sup_this":_vm.sup_this})]}}],null,true)})],1)],1),_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }